import React from "react";

// Keller Big Cart Image
import KellerBigCart from "../../../Assets/images/Branches/Keller/KellerBigCart.jpg";
import KellerBigCartMob from "../../../Assets/images/Branches/Keller/KellerBigCartMob.jpg";
// Keller Small Cart Images
import KellerSCart1 from "../../../Assets/images/Branches/Keller/KellerSCart1.jpg";
import KellerSCart2 from "../../../Assets/images/Branches/Keller/KellerSCart2.jpg";
import KellerSCart3 from "../../../Assets/images/Branches/Keller/KellerSCart3.jpg";
import KellerSCart4 from "../../../Assets/images/Branches/Keller/KellerSCart4.jpg";
import KellerSCart5 from "../../../Assets/images/Branches/Keller/KellerSCart5.jpg";
import KellerSCart6 from "../../../Assets/images/Branches/Keller/KellerSCart6.jpg";

// Keller Big Cart Data
export const kellerBigCartData = {
  title: "Keller SERVICES",
  subtitle: "Your Ride, Our Care",
  serviceTitle: "Self Service Car Wash",
  price: "Starting at $3",
  leftItems: [
    "Tire and Wheel Cleaning",
    "Engine Cleaning",
    <>
      Window and Chrome Cleaning <br /> (Low-Pressure Presoak)
    </>,
    <>
      High-Pressure Soap <br /> (High Pressure Presoak)
    </>,
    "High-Pressure Rinse",
  ],
  rightItems: ["Foam Brush", "Triple-Foam Polish", "Clear-Coat Protectant/Wax Coating", "Spot-Free Rinse", "Air Dryer"],
  imageSrc: KellerBigCart,
  imageSrcMob: KellerBigCartMob,
  imageAlt: "Keller - Self Service car wash",
};

// Keller Small Cart Data
export const kellerSmallCartsData = [
  {
    serviceTitle: (
      <>
        Laser wash <br /> Touch-Free Automatic
      </>
    ),
    price: "Best wash $20",
    leftItems: [
      "Hot Wax & Shine",
      "Clear coat protectant",
      "High Pressure Wash",
      "Tire Dressing",
      <>
        Foaming PRE-SOAK <br /> (2 passes)
      </>,
      "Under Body Blast",
      "Spot Free Rinse",
      "Windshield Cleaner",
    ],
    imageSrc: KellerSCart1,
    imageAlt: "Keller Best touch free car wash",
    height: "29vw",
  },
  {
    serviceTitle: (
      <>
        Laser wash <br /> Touch-Free Automatic
      </>
    ),
    price: "Better wash $15",
    leftItems: [
      <>
        <div className="IsMobileKeller">
          Foaming PRE-SOAK <br /> (2 passes)
        </div>
      </>,
      "Under Body Blast",
      "Spot Free Rinse",
      "Windshield Cleaner",
      "Clear Coat protectant",
      "High Pressure Wash",
      "Tire Dressing",
    ],
    imageSrc: KellerSCart2,
    imageAlt: "Keller Better laser car wash",
    height: "29vw",
  },
  {
    serviceTitle: (
      <>
        Laser wash <br /> Touch-Free Automatic
      </>
    ),
    price: "Good Wash: $10",
    leftItems: ["Foaming PRE-SOAK", "Spot Free Rinse", "Under Body Blast", "High Pressure Wash", "Clear Coat protectant"],
    imageSrc: KellerSCart3,
    imageAlt: "Keller good touch free car wash",
    height: "29vw",
  },
  {
    serviceTitle: (
      <>
        Laser wash <br /> Touch-Free Automatic
      </>
    ),
    price: "Basic wash $5",
    leftItems: ["Foaming PRE-SOAK", "Spot Free Rinse", "High Pressure Wash", "Clear Coat protectant"],
    imageSrc: KellerSCart4,
    imageAlt: "Keller Basic automatic car wash",
    height: "24vw",
  },
  {
    serviceTitle: "Vacuum Services",
    price: "Starting At $2",
    leftItems: ["Shampoo Vacuum", "Power Vacuum", "Fragrance Vacuum", "Air Vacuum"],
    imageSrc: KellerSCart5,
    imageAlt: "Keller car vacuum service",
    height: "24vw",
  },
  {
    serviceTitle: "Vending Products",
    price: "Starting At $2",
    leftItems: ["Disposable Towels", "Microfiber Towels", "Air Fresheners", "Armor All Products", "Variety of Other Car-Care", "Cold Drinks"],
    imageSrc: KellerSCart6,
    imageAlt: "Keller Vending machine",
    height: "24vw",
  },
];
