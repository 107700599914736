import React from "react";

// Grapevine Big Cart Image
import GrapevineBigCart from "../../../Assets/images/Branches/Grapevine/GrapevineBigCart.jpg";
import GrapevineBigCartMob from "../../../Assets/images/Branches/Grapevine/GrapevineBigCartMob.jpg";

// Grapevine Small Cart Images
import GrapevineSCart1 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart1.jpg";
import GrapevineSCart2 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart2.jpg";
import GrapevineSCart3 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart3.jpg";
import GrapevineSCart4 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart4.jpg";
import GrapevineSCart5 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart5.jpg";
import GrapevineSCart6 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart6.jpg";
import GrapevineSCart7 from "../../../Assets/images/Branches/Grapevine/GrapevineSCart7.jpg";

// Grapevine Big Cart Data
export const grapevineBigCartData = {
  title: "Grapevine location SERVICES",
  subtitle: "fun, convenient & affordable",
  serviceTitle: "Self Service Car Wash",
  price: "Starting at $3",
  leftItems: [
    "Tire and Wheel Cleaning",
    "Engine Cleaning",
    <>
      Window and Chrome Cleaning <br /> (Low-Pressure Presoak)
    </>,
    <>
      High-Pressure Soap <br />
      (High Pressure Presoak)
    </>,
    "High-Pressure Rinse",
  ],
  rightItems: ["Foam Brush", "Triple-Foam Polish", "Clear-Coat Protectant/Wax Coating", "Spot-Free Rinse", "Air Dryer"],
  imageSrc: GrapevineBigCart,
  imageSrcMob: GrapevineBigCartMob,
  imageAlt: "Grapevine Self Service car wash",
};
// Grapevine Small Cart Data
export const grapevineSmallCartsData = [
  // service 1
  {
    serviceTitle: (
      <>
        Kondor - KL1 <br /> Touch-Free Automatic
      </>
    ),
    price: "Best wash $22",
    leftItems: [
      "Side & Underbody Blast",
      "Triple High-Pressure Wash",
      "Luminaura Ceramic",
      "Spot Free Rinse",
      "Double Foaming Presoak",
      "Triple Foam Polish",
      "Carnauba Wax",
      "Max Blower",
    ],
    imageSrc: GrapevineSCart1,
    imageAlt: "Grapevine Best automatic touch free car wash",
    height: "29vw",
  },
  // service 2
  {
    serviceTitle: (
      <>
        Kondor - KL1 <br /> Touch-Free Automatic
      </>
    ),
    price: "Better wash $18",
    leftItems: [
      "Side & Underbody Blast",
      "Double High-Pressure Wash",
      "Clear coat protectant",
      "Max Blower",
      "Double Foaming Presoak",
      "Triple Foam Polish",
      "Spot Free Rinse",
    ],
    imageSrc: GrapevineSCart2,
    imageAlt: "Grapevine Better automatic car wash",
    height: "29vw",
  },
  // service 3
  {
    serviceTitle: (
      <>
        Kondor - KL1 <br /> Touch-Free Automatic
      </>
    ),
    price: "Good wash $12",
    leftItems: ["Double Foaming PRE-SOAK", "Clear coat protectant", "Max Blower", "Tire Dressing", "High Pressure Wash", "Spot Free Rinse"],
    imageSrc: GrapevineSCart3,
    imageAlt: "Grapevine Good touch free car wash",
    height: "29vw",
  },
  // service 4
  {
    serviceTitle: (
      <>
        Kondor - KL1 <br /> Touch-Free Automatic
      </>
    ),
    price: "Basic wash $8",
    leftItems: ["Foaming Presoak", "Spot Free Rinse", "Triple Foam Polish", "High-Pressure Wash", "Max Blower"],
    imageSrc: GrapevineSCart4,
    imageAlt: "Grapevine Basic touch free car wash",
    height: "25vw",
  },
  // service 5
  {
    serviceTitle: "Vending Products",
    price: "Starting at $1",
    leftItems: [
      "Disposable Towels",
      "Microfiber Towels",
      "Air Fresheners",
      "Armor All Products",
      <>
        Variety of Other <br /> Car-Care
      </>,
      "Cold Drinks",
      "Pet Vending Products",
    ],
    imageSrc: GrapevineSCart5,
    imageAlt: "Grapevine car wash products Vending",
    height: "25vw",
  },
  // service 6
  {
    serviceTitle: "Vacuum Services",
    price: "Starting at $2",
    leftItems: ["Shampoo Vacuum", "Power Vacuum", "Fragrance Vacuum", "Air Vacuum"],
    imageSrc: GrapevineSCart6,
    imageAlt: "Grapevine car wash vacuums",
    height: "25vw",
  },
  // service 7
  {
    serviceTitle: "Pet Wash Bays",
    price: "Starting at $8",
    leftItems: [
      "Tub Rinse Sanitizer",
      "Flea & Tick Treatment",
      "Oatmeal Shampoo",
      "Skunk Odor Remover",
      "Tearless Shampoo",
      "Conditioner Air Dryer",
      "Clean Pet Vacuum",
    ],
    imageSrc: GrapevineSCart7,
    imageAlt: "Grapevine Pet Wash service",
    height: "25vw",
  },
];
